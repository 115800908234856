/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Image from 'next/image';
import React, { useState } from 'react';
import { Play, XCircle } from 'react-feather';
import ReactPlayer from 'react-player';

export default function YoutubeBtn() {
  const [videoOpen, setVideoOpen] = useState(false);
  return (
    <>
      {videoOpen && (
        <div data-aos="fade" className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen p-5 sm:p-10 md:p-28 bg-black/90">
          <button
            onClick={() => setVideoOpen(!videoOpen)}
            className="absolute top-0 right-0 p-2 m-5 text-white rounded-full md:p-1 md:m-2 bg-brand-main"
          >
            <XCircle />
          </button>
          <div className="w-full overflow-hidden h-1/2 max-h-[1000px] max-w-[1400px] sm:h-full rounded-xl" data-aos-delay="200" data-aos="fade-up">
            <ReactPlayer
              width="100%"
              height="100%"
              url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            />
          </div>
        </div>
      )}
      <div className="absolute bottom-0 z-20 p-8 transition-all duration-200 translate-x-1/2 translate-y-1/2 rounded-full md:translate-x-0 right-1/2 md:right-0 md:mr-10 bg-brand-main/20 ">
        <div className="relative rounded-full w-44 h-44 md:w-64 md:h-64" data-aos="zoom-in">
          <Image
            src="https://placehold.co/600x400.png"
            fill
            className="object-cover rounded-full"
            alt="Video"
          />
          <div
            onClick={() => setVideoOpen(!videoOpen)}
            className="absolute p-3 !cursor-pointer text-white translate-x-1/2 translate-y-1/2 rounded-full md:p-5 right-1/2 bottom-1/2 bg-brand-main"
          >
            <Play />
          </div>
        </div>
      </div>
    </>
  );
}
