/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import Link from 'next/link';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import Image from 'next/image';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { Player } from '@lottiefiles/react-lottie-player';
import YoutubeBtn from './YoutubeBtn';

export default function Hero({ data }) {
  if (data && data.length === 0) {
    return null;
  }
  const parallaxContainerRef = useRef(null);

  function divideString(str) {
    const words = str.split(' ');
    const groups = [];

    for (let i = 0; i < words.length; i += 4) {
      groups.push(words.slice(i, i + 4).join(' '));
    }

    return groups;
  }

  return (
    <section className="relative w-full" ref={parallaxContainerRef}>
      <Swiper
        navigation
        pagination={{ clickable: true }}
        grabCursor
        autoplay={{
          delay: 12000,
        }}
        loop
        modules={[Navigation, Pagination, Autoplay]}
        className="relative w-full"
        style={{
          '--swiper-pagination-color': '#a68e7b',
          '--swiper-navigation-color': '#a68e7b',
          '--swiper-pagination-bullet-inactive-color': '#fff',
        }}
      >
        {data.map((item, idx) => (
          <SwiperSlide key={idx} className="relative h-full">
            <div
              className="absolute z-30 w-full h-full"
              style={{
                background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
              }}
            />
            <div className="relative w-full h-[90vh] parallax-bg">
              <Image
                fill
                src={item.imageBg}
                alt={item.title}
                className="object-cover object-top"
                sizes="100vw"
                quality={50}
                priority={idx === 0}
              />
            </div>
            <div className="absolute top-0 z-40 w-full px-5">
              <div className="flex flex-col justify-center w-full max-w-xl mx-auto h-[90vh] md:max-w-5xl 2xl:max-w-7xl">
                <div className="flex w-full">
                  <Player
                    autoplay
                    loop
                    src={`./lotties/hero-${idx + 1}.json`}
                    className="w-20 h-20 md:w-32 md:h-32"
                  />
                </div>
                <div className="pb-5 text-3xl md:pb-12 md:text-5xl payfair text-brand-main">
                  {divideString(item.title).map((i) => (
                    <p key={i} className="p-2 my-2 bg-white w-fit" data-aos="fade-up">
                      {i}
                    </p>
                  ))}
                </div>
                <Link
                  href={item.url}
                  className="px-5 py-3 font-semibold text-white transition-all duration-200 payfair w-fit bg-brand-btn hover:bg-brand-btn"
                  data-aos-delay="600"
                  data-aos="fade-up"
                >
                  {item.callToAction}
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <YoutubeBtn />
    </section>
  );
}

Hero.propTypes = {
  data: PropTypes.array,
};
